import React from "react"
import styled from "styled-components"
import Image from "../images/pageNotFound.png"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import variables from "../components/GlobalVariables"

const NotFound = styled.div`
  width: 100%;
  height: 90vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
`
const ImageNotFound = styled.img`
  width: 80%;
  height: auto;
  margin: 0 auto;
  max-width: 291px;
`

const LinkStyle = styled(Link)`
  color: ${variables.accentColor};
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFound>
      {" "}
      <ImageNotFound src={Image} alt="404 Page not found" />
      <br />
      <h1>This is not the page you are looking for...</h1>
      <br />
      <h3>
        Take me <LinkStyle to="/">Home</LinkStyle>
      </h3>
    </NotFound>
  </Layout>
)

export default NotFoundPage
